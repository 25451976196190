import { useCallback, useEffect, useRef, useState } from 'react';

import type { UseIntervalCounterProps } from './useIntervalCounter.type';

/**
 * Hook used to count down int numbers
 * @param counterStartValue the value from which the counter starts counting
 * @param intervalTime time to decrease counter in ms
 * @returns object with `counter` value => `{ counter }`
 */
export const useIntervalCounter = ({
  counterStartValue,
  intervalTime = 1000,
}: UseIntervalCounterProps) => {
  const [counter, setCounter] = useState(counterStartValue);
  const timeoutIdRef = useRef<NodeJS.Timer | null>(null);

  const clearCounterTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (counter <= 0) {
      clearCounterTimeout();
      return;
    }

    timeoutIdRef.current = setTimeout(() => {
      setCounter(counter - 1);
    }, intervalTime);

    return clearCounterTimeout;
  }, [counter, clearCounterTimeout, intervalTime]);

  return { counter };
};
