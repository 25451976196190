import { useMemo } from 'react';
import type { ReactNode } from 'react';

import { ChkStatusScreen } from '@apps/checkout-components';
import type { ChkStatusScreenProps } from '@apps/checkout-components';

import { getComponentByStatus } from '@apps/checkout-contexts';

import { AutoRedirectionButton } from '../../../components/AutoRedirectionButton/AutoRedirectionButton';
import { AUTO_REDIRECTION_TIME } from '../../../global.constants';
import type { PaymentStatus } from '../../../global.types';
import {
  BANK_REDIRECT_BUTTON,
  BANK_REDIRECT_TEXT,
  BANK_REDIRECT_TITLE,
  CANCELLED_BY_USER_TEXT,
  CANCELLED_BY_USER_TITLE,
  ERROR_AT_BANK_TEXT,
  ERROR_AT_BANK_TITLE,
  ERROR_TEXT,
  ERROR_TITLE,
  FAILED_TEXT,
  FAILED_TITLE,
  PENDING_TEXT,
  PENDING_TITLE,
  RETURN_TO_MERCHANT_BUTTON,
  SUCCEED_TEXT,
  SUCCEED_TITLE,
} from './useGetStatusView.constants';
import type {
  UseGetStatusView,
  UseGetStatusViewProps,
} from './useGetStatusView.types';

export const useGetStatusView: UseGetStatusView = ({
  result,
  isLoading,
  isReady,
}: UseGetStatusViewProps) => {
  const STATUS_PROPS = useMemo(
    () =>
      ({
        SUCCEED: {
          animation: 'success',
          loop: false,
          title: SUCCEED_TITLE,
          text: SUCCEED_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        FAILED: {
          animation: 'failed',
          loop: false,
          title: FAILED_TITLE,
          text: FAILED_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        PENDING: {
          animation: 'pending',
          title: PENDING_TITLE,
          text: PENDING_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        CANCELLED_BY_USER: {
          animation: 'failed',
          loop: false,
          title: CANCELLED_BY_USER_TITLE,
          text: CANCELLED_BY_USER_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        ERROR: {
          animation: 'failed',
          loop: false,
          title: ERROR_TITLE,
          text: ERROR_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        ERROR_AT_BANK: {
          animation: 'failed',
          loop: false,
          title: ERROR_AT_BANK_TITLE,
          text: ERROR_AT_BANK_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              buttonText={RETURN_TO_MERCHANT_BUTTON}
            />
          ),
        },
        BANK_REDIRECT: {
          animation: 'mobileLoading',
          title: BANK_REDIRECT_TITLE,
          text: BANK_REDIRECT_TEXT,
          children: (
            <AutoRedirectionButton
              time={AUTO_REDIRECTION_TIME}
              redirectUrl={result?.details.redirectUrl}
              allowAutoRedirection={false}
              buttonText={BANK_REDIRECT_BUTTON}
            />
          ),
        },
      } satisfies Record<PaymentStatus, ChkStatusScreenProps>),
    [result?.details.redirectUrl]
  );

  const STATUS_MAP = useMemo(
    () =>
      ({
        SUCCEED: <ChkStatusScreen {...STATUS_PROPS.SUCCEED} />,
        FAILED: <ChkStatusScreen {...STATUS_PROPS.FAILED} />,
        PENDING: <ChkStatusScreen {...STATUS_PROPS.PENDING} />,
        CANCELLED_BY_USER: (
          <ChkStatusScreen {...STATUS_PROPS.CANCELLED_BY_USER} />
        ),
        ERROR: <ChkStatusScreen {...STATUS_PROPS.ERROR} />,
        ERROR_AT_BANK: <ChkStatusScreen {...STATUS_PROPS.ERROR_AT_BANK} />,
        BANK_REDIRECT: <ChkStatusScreen {...STATUS_PROPS.BANK_REDIRECT} />,
      } satisfies Record<PaymentStatus, ReactNode>),
    [STATUS_PROPS]
  );

  const view = useMemo(
    () =>
      getComponentByStatus({
        status: result?.status,
        componentsMap: STATUS_MAP,
        isLoading,
        loadingComponent: <ChkStatusScreen animation="loading" />,
        isReady,
      }),
    [isLoading, STATUS_MAP, result?.status, isReady]
  );

  return { view };
};
