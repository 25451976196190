import { COMMON_HEADERS_KEYS } from '@apps/checkout-utils';

export const X_VOLT_API_VERSION = '4';

export const REQUEST_ARGS: RequestInit = {
  method: 'POST',
  headers: {
    [COMMON_HEADERS_KEYS.X_VOLT_API_VERSION]: X_VOLT_API_VERSION,
    [COMMON_HEADERS_KEYS.CONTENT_TYPE]: 'application/json',
  },
};

export const ORIGIN_APP = 'statusApp';

// Time in seconds
export const AUTO_REDIRECTION_TIME = 3;
