export const SUCCEED_TITLE = 'Payment success!';
export const SUCCEED_TEXT = 'Woohoo, your payment has been successful.';

export const FAILED_TITLE = 'Payment Failed';
export const FAILED_TEXT =
  "Oops, it appears there's an issue. No funds have moved from your account.";

export const PENDING_TITLE = 'Processing transaction...';
export const PENDING_TEXT = 'Please wait, your transaction is being processed.';

export const CANCELLED_BY_USER_TITLE = 'Payment cancelled';
export const CANCELLED_BY_USER_TEXT =
  'Sorry to see you go. No funds have moved from your account.';

export const ERROR_TITLE = 'Something went wrong';
export const ERROR_TEXT = `Oops, it appears there's an issue. No funds have moved from your account.`;

export const ERROR_AT_BANK_TITLE = 'Bank Error';
export const ERROR_AT_BANK_TEXT =
  'There seems to be an issue with your bank. No funds were moved from your account.';

export const BANK_REDIRECT_TITLE = 'Approve your payment';
export const BANK_REDIRECT_TEXT =
  'Tap the button to open your banking app and approve your payment securely.';
export const BANK_REDIRECT_BUTTON = 'Continue';

export const RETURN_TO_MERCHANT_BUTTON = 'Restart the demo';
