import { useEffect, useMemo } from 'react';

import { COMMON_EVENTS, trackEvent } from '@apps/checkout-utils';

import { ORIGIN_APP } from '../../../global.constants';
import type { UseStatusAppEventsProps } from './useStatusAppEvents.types';

export const useStatusAppEvents = ({ result }: UseStatusAppEventsProps) => {
  const eventsMap = useMemo(
    () => ({
      SUCCEED: COMMON_EVENTS.SUCCESS_SCREEN,
      FAILED: COMMON_EVENTS.FAILURE_SCREEN,
      PENDING: COMMON_EVENTS.PENDING_SCREEN,
      CANCELLED_BY_USER: COMMON_EVENTS.CANCEL_SCREEN,
      ERROR: COMMON_EVENTS.ERROR_SCREEN,
      ERROR_AT_BANK: COMMON_EVENTS.ERROR_SCREEN,
      BANK_REDIRECT: COMMON_EVENTS.BANK_REDIRECT_SCREEN,
    }),
    []
  );

  useEffect(() => {
    if (result?.status && eventsMap[result?.status]) {
      trackEvent(eventsMap[result?.status], {
        originApp: ORIGIN_APP,
        ...(result.status === 'ERROR_AT_BANK' && {
          errorSource: 'Error at bank',
        }),
        checkoutVersion: '3.0',
      });
    }
  }, [result?.status, eventsMap]);
};
