import { Box, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';

import { ChkButton } from '@apps/checkout-components';

import { locationAssign, useIntervalCounter } from '@apps/checkout-utils';

import {
  AUTO_REDIRECTION_TEXT,
  getButtonText,
} from './AutoRedirectionButton.constants';
import type { AutoRedirectionButtonProps } from './AutoRedirectionButton.types';

export const AutoRedirectionButton = ({
  redirectUrl,
  time,
  allowAutoRedirection = true,
  buttonText,
}: AutoRedirectionButtonProps) => {
  const { counter } = useIntervalCounter({
    counterStartValue: time,
  });

  const redirectUser = useCallback(() => {
    if (redirectUrl) {
      locationAssign(redirectUrl);
    }
  }, [redirectUrl]);

  const btnText = useMemo(
    () =>
      allowAutoRedirection ? getButtonText(buttonText, counter) : buttonText,
    [counter, allowAutoRedirection, buttonText]
  );

  useEffect(() => {
    if (allowAutoRedirection && counter <= 0) {
      redirectUser();
    }
  }, [counter, redirectUser, allowAutoRedirection]);

  return (
    <AutoRedirectionButtonWrapper>
      <StyledAutoRedirectionButton
        variant="contained"
        href={redirectUrl}
        onClick={redirectUser}
      >
        {btnText}
      </StyledAutoRedirectionButton>
      {allowAutoRedirection && (
        <Typography variant="body2" letterSpacing={-0.3}>
          {AUTO_REDIRECTION_TEXT}
        </Typography>
      )}
    </AutoRedirectionButtonWrapper>
  );
};

const AutoRedirectionButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(3),
  color: theme.tokens.coreMainSecondary,
}));

const StyledAutoRedirectionButton = styled(ChkButton)(({ theme }) => ({
  marginTop: theme.spacing(6),
  fontWeight: theme.typography.fontWeightBold,
  width: '100%',
}));
