export const getMergedSearchAndHashParams = (
  url: string
): URLSearchParams | null => {
  try {
    const { searchParams, hash } = new URL(url);

    const entries = [
      ...new URLSearchParams(searchParams).entries(),
      ...new URLSearchParams(hash.substring(1)).entries(),
    ];

    return new URLSearchParams(entries);
  } catch (error) {
    console.error(error);
    return null;
  }
};
