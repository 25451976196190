import { Box, styled } from '@mui/material';

import { ChkPageWrapper } from '@apps/checkout-components';

import { useGetStatusView } from './hooks/useGetStatusView/useGetStatusView.hook';
import { useRedeem } from './hooks/useRedeem/useRedeem.hook';
import { useStatusAppEvents } from './hooks/useStatusAppEvents/useStatusAppEvents.hook';

export function App() {
  const { isLoading, result, isNotExecuted } = useRedeem();
  const { view } = useGetStatusView({
    result,
    isLoading,
    isReady: !isNotExecuted,
  });
  useStatusAppEvents({ result });

  return (
    <ChkPageWrapper>
      <StyledPageContainer>{view}</StyledPageContainer>
    </ChkPageWrapper>
  );
}

export default App;

const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: [0, theme.spacing(6), theme.spacing(6)].join(' '),
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    padding: [theme.spacing(6), theme.spacing(8)].join(' '),
    margin: ['auto', theme.spacing(9)].join(' '),
  },
}));
