export const COMMON_EVENTS = {
  ERROR_SCREEN: 'Error screen view',
  SUCCESS_SCREEN: 'Payment success screen view',
  FAILURE_SCREEN: 'Payment failure screen view',
  PENDING_SCREEN: 'Payment pending screen view',
  CANCEL_SCREEN: 'Payment cancel screen view',
  SUMMARY_SCREEN: 'Payment summary screen view',
  PAY_AT_MY_BANK_CLICK: 'Pay at my bank click',
  BANK_REDIRECT_SCREEN: 'Bank redirect screen view',
} as const;
