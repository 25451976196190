import { useEffect } from 'react';

import {
  API_ENDPOINTS,
  getPaymentRequestId,
  useApi,
} from '@apps/checkout-utils';

import { REQUEST_ARGS } from '../../../global.constants';
import type { RedeemBody, RedeemResponse } from '../../../global.types';
import { getMergedSearchAndHashParams } from '../../../utils/index.utils';

export const useRedeem = () => {
  const { executeRequest, status, ...rest } = useApi<RedeemResponse>(
    API_ENDPOINTS.redeemPayment(),
    REQUEST_ARGS
  );

  useEffect(() => {
    executeRequest({
      querystring: getMergedSearchAndHashParams(document.URL),
      body: JSON.stringify({
        paymentId: getPaymentRequestId(),
      } satisfies RedeemBody),
    });
  }, [executeRequest]);

  return {
    executeRequest,
    status,
    ...rest,
    isNotExecuted: status === 'not-executed',
  };
};
